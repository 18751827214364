import Controller from './controller'

export default class extends Controller {
  static get options() {
    return {
      pastOnly: false,
    }
  }

  async initialize() {
    let Pikaday = await import(/* webpackChunkName: "pikaday" */ 'pikaday')
    Pikaday = Pikaday.default
    
    const minDate = !this.options.pastOnly ? new Date() : null
    const maxDate = this.options.pastOnly ? new Date() : null
    
    this.picker = new Pikaday({
      field: this.element,
      format: 'DD-MM-YY',
      minDate: minDate,
      maxDate: maxDate,
    })
  }
}
